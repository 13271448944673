import React from 'react';
import { useStaticQuery, graphql, Link } from 'gatsby';

import { Container, Section } from '@components/common';
import ContentCard from '@components/ContentCard';

type ImageNode = {
    sourceUrl: string;
}

type FeaturedImage = {
    node: ImageNode;
};

type Solution = {
    id: string;
    title: string;
    excerpt: string;
    slug: string;
    date: string;
    featuredImage: FeaturedImage;
};

const SolutionsList = () => {
    const data = useStaticQuery(graphql`
        query {
            solutions: allWpSolution(limit: 8) {
                nodes {
                    title
                    excerpt
                    id
                    slug
                    date(formatString: "dddd, MMMM D YYYY")
                    featuredImage {
                        node {
                            sourceUrl
                        }
                    }
                }
            }
        }
    `);

    return (
        <>
        <Section verticalSpacing="default" className="bg-shade50">
            <Container>
                <div className='pt-8 pb-16 border-b border-shade100'>
                    <h2 className="mb-gap-large">Solutions</h2>
                    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-2 md:gap-4 -mx-5">
                        {data.solutions.nodes.map((sol: Solution) => (
                            <div key={sol.slug} className="w-full max-w-xl px-5 mb-gap-mini">
                                <ContentCard
                                    link={`/solution/${sol.slug}`}
                                    imageSourceUrl={sol.featuredImage?.node.sourceUrl}
                                    title={sol.title}
                                    excerpt={sol.excerpt}
                                    linkText="Read more"
                                />
                            </div>
                        ))}
                    </div>
                </div>
            </Container>
        </Section>
        </>
    )
}

export default SolutionsList;