import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'

import { Container, Section, StyledLink } from '@components/common'
import { Button } from '@mrs-ui'

const Hero = () => {
  const data = useStaticQuery(graphql`
    query {
      image: file(
        relativePath: {
          eq: "images/stock/h007.webp"
        }
      ) {
        childImageSharp {
          fluid(maxHeight: 500) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  return (
    <Section
      verticalSpacing="xxl"
      backgroundImage={data.image.childImageSharp.fluid}
      theme="dark"
      overlay
    >
      <Container>
        <div className="md:w-5/12 w-full">
          <h1 className="mb-8">Industry leading solutions for businesses of all sizes</h1>
          <p className="text-large">
            See the products and solutions that MRS offers to help you build your business.
          </p>
          <Button appearance="primary" link="/contact" className="mt-6">
            Let's talk about your solution
          </Button>
        </div>
      </Container>
    </Section>
  )
}
export default Hero
