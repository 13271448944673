import Head from '@components/Head'
import { Hero } from '@components/pages/solutions'
import SolutionsList from '@components/pages/solutions/SolutionsList'
import React from 'react'

const Solutions = () => {
  return (
    <>
      <Head
        title="MRS Solutions and Products"
        description="See products and solutions that MRS offers to help you build your business."
      />
      <Hero />
      <SolutionsList />
    </>
  )
}

export default Solutions
